import {
    ApplicationRef,
    ComponentFactory,
    ComponentFactoryResolver,
    Injectable,
    Injector,
    NgModuleRef,
} from "@angular/core";
import { jSith } from "../util/jquery-replacement";
import { TypeMapService } from "./type.map.service";

@Injectable({
    providedIn: "root",
})
export class CompileService {
    constructor(
        private injector: Injector,
        private ngModRef: NgModuleRef<any>,
        private app: ApplicationRef,
        private typeMapService: TypeMapService,
        private resolver: ComponentFactoryResolver,
    ) {}

    public static getInstance() {
        return window.angBridge.CompileService;
    }

    compileElement(element: Element, model?: any) {
        // here we loop though the factories, find the element based on the selector
        // let factories = (this.ngModRef.componentFactoryResolver as any)._factories;
        let found = false;

        for (let selector in this.typeMapService.typeMap) {
            let comp: ComponentFactory<any>;
            try {
                comp = this.resolver.resolveComponentFactory(this.typeMapService.typeMap[selector]);
            } catch (e) {
                console.log(e.message);
                continue;
            }

            let list = Array.prototype.slice.call(element.querySelectorAll(comp.selector));
            element.matches(comp.selector) && list.unshift(element);
            if (!found) {
                list.forEach((item: Element) => {
                    found = true;
                    let parent = item.parentNode;
                    let next = item.nextSibling;
                    let ngContentNodes: any[][] = []; // this is for the viewchild/viewchildren of this object

                    comp.ngContentSelectors.forEach((sel: string) => {
                        let ngContentList: any[] = [];

                        // all children;
                        if (sel === "*") {
                            item.childNodes.forEach((c: ChildNode) => {
                                ngContentList.push(c);
                            });
                        } else {
                            let selList = item.querySelectorAll(sel);
                            selList.forEach((l: Element) => {
                                ngContentList.push(l);
                            });
                        }

                        ngContentNodes.push(ngContentList);
                    });

                    // here is where we compile the factory based on the node we have
                    let component = comp.create(this.injector, ngContentNodes, item, this.ngModRef);

                    if (model) {
                        jSith.forEach(model, (key: any, value: any) => {
                            (component.instance as any)[key] = value;
                        });
                        model = null;
                    }

                    this.app.attachView(component.hostView);

                    // we need to move the newly compiled element, as it was appended to this components html
                    if (next) {
                        parent.insertBefore(component.location.nativeElement, next);
                    } else {
                        parent.appendChild(component.location.nativeElement);
                    }

                    component.changeDetectorRef && component.changeDetectorRef.detectChanges();
                    component.hostView.detectChanges && component.hostView.detectChanges(); // tell the component to detectchanges
                });
            }
        }
    }

    compileHTML(html: string, target?: HTMLElement, model?: any) {
        target = target || document.body;

        let parentEl = document.createElement("div");
        parentEl.innerHTML = html;

        jSith.forEach(parentEl.children, (_: any, child: Element) => {
            target.appendChild(child);
            this.compileElement(child, model);
        });
    }
}
