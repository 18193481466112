//@author: devin

//declare window so we can reference angBridge here
import { S25Util } from "../util/s25-util";

declare global {
    interface Window {
        angBridge: any;
    }
}

class TimeoutFactory {
    public static checkIsFunction(func: Function, name: string) {
        if (typeof func !== "function") {
            throw new Error(`@Timeout can only be used on methods and functions. ${name} is not a method or function.`);
        }
    }
    public static getTimeoutFn(func: any) {
        return function (...args: any[]) {
            let promise: any = func.apply(this, args);
            return window.angBridge.$timeout(function () {
                return promise;
            });
        };
    }
}

export function Timeout(target: any, propertyKey: string): void;
export function Timeout(target: any, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor;
export function Timeout(target: any, propertyKey: string, descriptor?: PropertyDescriptor) {
    if (descriptor) {
        // Method
        if ("value" in descriptor) {
            const func = descriptor.value;
            descriptor.value = TimeoutFactory.getTimeoutFn(func);
        } else if ("get" in descriptor) {
            const func = descriptor.get;
            descriptor.get = TimeoutFactory.getTimeoutFn(func);
        }

        return descriptor;
    } else {
        // Property
        let func: () => any;
        Object.defineProperty(target, propertyKey, {
            configurable: true,
            get() {
                return func;
            },
            set(newFunc: Function) {
                if (S25Util.isDefined(newFunc)) TimeoutFactory.checkIsFunction(newFunc, propertyKey);
                func = TimeoutFactory.getTimeoutFn(newFunc);
            },
        });
    }
}
