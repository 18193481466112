import { TelemetryService } from "../services/telemetry.service";

export interface TelemetryOptions {
    category: string;
    subCategory?: string;
    type: string;
}

export function Telemetry(options: TelemetryOptions) {
    function getNewFunc(func: Function) {
        return function (...args: any[]) {
            TelemetryService.sendWithSub(options.category, options.subCategory, options.type);
            return func.apply(this, args);
        };
    }

    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        if ("value" in descriptor) {
            descriptor.value = getNewFunc(descriptor.value);
        } else if ("get" in descriptor) {
            descriptor.get = getNewFunc(descriptor.get);
        }
        return descriptor;
    };
}
