//@author: devin
import { BrowserModule } from "@angular/platform-browser";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, NgZone } from "@angular/core";
import { S25HelpModule } from "../modules/s25-help/s25.help.module";
import { S25ToggleButtonModule } from "../modules/s25-toggle-button/s25.toggle.button.module";
import { S25EditableBooleanModule } from "../modules/s25-editable/s25-editable-boolean/s25.editable.boolean.module";
import { S25MultiQueryModule } from "../modules/s25-multi-query/s25.multi.query.module";
import { S25AddressModule } from "../modules/s25-address/s25.address.module";
import { S25NumWeeksChooserModule } from "../modules/s25-num-weeks-chooser/s25.num.weeks.chooser.module";
import { S25ItemModule } from "../modules/s25-item/s25.item.module";
import { ServiceLoader } from "../services/service.loader";
import { ModalModule } from "../modules/modal/modal.module";
import { PopoverModule } from "../modules/s25-popover/popover.module";
import { AppComponent } from "./app.component";
import { CompileService } from "./compile.service";
import { S25ImageModule } from "../modules/s25-image/s25.image.module";
import { EmailDefaultsModule } from "../modules/system-settings/email-defaults/email.defaults.module";
import { SystemDefaultsModule } from "../modules/system-settings/system-defaults/system.defaults.module";
import { UserDefaultsModule } from "../modules/system-settings/user-defaults/user.defaults.module";
import { EventSettingsModule } from "../modules/system-settings/event-settings/event-settings.module";
import { LocationSettingsModule } from "../modules/system-settings/location-settings/location-settings.module";
import { ResourceSettingsModule } from "../modules/system-settings/resource-settings/resource-settings.module";
import { OrganizationSettingsModule } from "../modules/system-settings/organization-settings/organization-settings.module";
import { TaskSettingsModule } from "../modules/system-settings/task-settings/task-settings.module";
import { S25RichTextEditorModule } from "../modules/s25-rich-text-editor/s25.rich.text.editor.module";
import { S25BreakpointModule } from "../modules/s25-breakpoint/s25.breakpoint.module";
import { S25DatetimePrefsModule } from "../modules/s25-datetime-prefs/s25.datetime.prefs.module";
import { S25TimepickerModule } from "../modules/s25-timepicker/s25.timepicker.module";
import { S25DatepickerModule } from "../modules/s25-datepicker/s25.datepicker.module";
import { S25LoadingInlineModule } from "../modules/s25-loading/s25.loading.inline.module";
import { S25DropdownPaginatedModule } from "../modules/s25-dropdown/s25.dropdown.paginated.module";
import { S25DarkmodeModule } from "../modules/s25-darkmode/s25.darkmode.module";
import { S25SimpleCollapseModule } from "../modules/s25-simple-collapse/s25.simple.collapse.module";
import { S25NotificationPolicyModule } from "../modules/s25-notification-policy/s25.notification.policy.module";
import { S25LynxModule } from "../modules/s25-lynx/s25.lynx.module";
import { S25EditableModule } from "../modules/s25-editable/s25.editable.module";
import { BpeModule } from "../modules/bpe/bpe.module";
import { EventStateDefaultModule } from "../modules/system-settings/event-form-settings/event-state-default/event.state.default.module";
import { S25MultiselectModule } from "../modules/s25-multiselect/s25-multiselect.module";
import { S25BulkEditModule } from "../modules/s25-bulk-edit/s25.bulk.edit.module";
import { S25SecurityLinkModule } from "../modules/s25-security-link/s25.security.link.module";
import { S25PipesModule } from "../pipes/s25.pipes.module";
import { S25DeleteModule } from "../modules/s25-delete/s25.delete.module";
import { PublisherSettingsModule } from "../modules/system-settings/publisher-settings/publisher.settings.module";
import { S25PricingModule } from "../modules/s25-pricing/s25.pricing.module";
import { S25MoreActionsModule } from "../modules/s25-more-actions/s25.more.actions.module";
import { S25ContactInfoModule } from "../modules/s25-contact-info/s25.contact.module";
import { S25EventTypeHierarchyModule } from "../modules/event-type-hierarchy/s25.event.type.hierarchy.module";
import { EventFormSettingsModule } from "../modules/system-settings/event-form-settings/event.form.settings.module";
import { S25ImageUploadModule } from "../modules/s25-image-upload/s25.image.upload.module";
import { S25ImageSelectorModule } from "../modules/s25-image-selector/s25.image.selector.module";
import { S25ImageFormModule } from "../modules/s25-image-form/s25.image.form.module";
import { S25ChangePasswordModule } from "../modules/s25-change-password/s25.change.password.module";
import { S25MasqueradeModule } from "../modules/S25-masquerade/masquerade.module";
import { S25ColumnChooserModule } from "../modules/s25-column-chooser/s25.column.chooser.module";
import { S25DayChooserModule } from "../modules/s25-day-chooser/s25.day.chooser.module";
import { S25CancelRequestModule } from "../modules/s25-cancel-request/s25.cancel.request.module";
import { S25PreferenceToggleModule } from "../modules/s25-preference-toggle/s25.preference.toggle.module";
import { S25RegisterButtonModule } from "../modules/s25-register-button/s25.register.button.module";
import { S25LocationAlwaysShareModule } from "../modules/s25-location-always-share/s25.location.always.share.module";
import { S25OptModule } from "../modules/s25-opt/s25.opt.module";
import { S25EventStatesModule } from "../modules/s25-event-states/s25.event.states.module";
import { S25ConflictsModule } from "../modules/s25-conflicts/s25.conflicts.module";
import { S25OfficeHoursSliderModule } from "../modules/s25-office-hours-slider/s25.office.hours.slider.module";
import { S25TaskModule } from "../modules/s25-task/s25.task.module";
import { S25ConstraintsModule } from "../modules/s25-constraints/s25.constraints.module";
import { S25ObjectModule } from "../modules/s25-object/s25.object.module";
import { S25DndSortableModule } from "../modules/s25-dnd-sortable/s25.dnd.sortable.module";
import { S25TableModule } from "../modules/s25-table/s25.table.module";
import { S25RuleTreeModule } from "../modules/s25-rule-tree/s25.rule.tree.module";
import { S25EventFormConfigsModule } from "../modules/system-settings/event-form-settings/s25-event-form-config/s25.event.form.configs.module";
import { S25EmailTemplateModule } from "../modules/system-settings/event-save-email/s25-email-template/s25.email.template.module";
import { S25InfoMessageModule } from "../modules/s25-info-message/s25.info.message.module";
import { S25MasterDefinitionsModule } from "../modules/master-definitions/s25.master.definitions.module";
import { S25EmailScenarioModule } from "../modules/system-settings/event-save-email/s25-email-scenario/s25.email.scenario.module";
import { S25RadioModule } from "../modules/s25-radio/s25.radio.module";
import { S25ChartsModule } from "../modules/s25-charts/s25.charts.module";
import { S25CustomAttributeModule } from "../modules/s25-custom-attribute/s25.custom.attribute.module";
import { S25TriggerRerenderModule } from "../modules/s25-trigger-rerender/s25.trigger.rerender.module";
import { S25WorkflowModule } from "../modules/system-settings/s25-workflow/s25.workflow.module";
import { S25DocumentManagementModule } from "../modules/system-settings/document-management/s25.document.management.module";
import { S25TaskViewModule } from "../modules/s25-task-view/s25.task.view.module";
import { S25EmbeddingModule } from "../modules/system-settings/embedding/s25-embedding/s25.embedding.module";
import { S25ModalModule } from "../modules/s25-modal/s25.modal.module";
import { S25IconModule } from "../modules/s25-icon/s25.icon.module";
import { S25QLModule } from "../modules/s25-ql/s25ql.module";
import { S25CaptureEventModule } from "../modules/s25-capture-event/s25.capture.event.module";
import { S25PublisherModule } from "../modules/s25-publisher/s25.publisher.module";
import { S25PillModule } from "../modules/s25-pill/s25.pill.module";
import { S25BoardModule } from "../modules/s25-swarm-schedule/s25.board.module";
import { S25ProgressBarModule } from "../modules/s25-progress-bar/s25.progress.bar.module";
import { S25OptimizerEffectiveDatingModule } from "../modules/s25-optimizer-effective-dating/s25.optimizer.effective.dating.module";
import { S25ShiftSelectableModule } from "../modules/s25-shift-select/s25.shift.selectable.module";
import { S25BlackoutManagementModule } from "../modules/system-settings/blackout-management/s25.blackout.management.module";
import { S25BuildingModule } from "../modules/s25-building/s25.building.module";
import { S25StandardScheduleModule } from "../modules/s25-standard-schedule/s25.standard.schedule.module";
import { S25DurationModule } from "../modules/s25-duration/s25.duration.module";
import { S25AvailabilityModule } from "../modules/s25-availability/s25.availability.module";
import { S25EventDetailsModule } from "../modules/s25-event/s25.event.details.module";
import { ContextMenuModule } from "../modules/s25-context-menu/context.menu.module";
import { S25EditablePropertyModule } from "../modules/s25-editable-property/s25.editable.property.module";
import { S25MeetingPatternGridModule } from "../modules/s25-meeting-pattern-grid/s25.meeting.pattern.grid.module";
import { S25VirtualGridModule } from "../modules/s25-virtual-grid/s25.virtual.grid.module";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { S25EventOccurrencesModule } from "../modules/s25-event/s25-event-occurrences/s25.event.occurrences.module";
import { S25StripeSettingsModule } from "../modules/system-settings/stripe-settings/s25.stripe.settings.module";
import { S25ObjectAuditListModule } from "../modules/s25-object-audit-list/s25.object.audit.list.module";
import { S25ScheduledEmailsModule } from "../modules/s25-scheduled-emails/s25.scheduled.emails.module";
import { S25ScrollMinimapComponent } from "../standalone/s25-scroll-minimap/s25.scroll.minimap.component";
import { S25ButtonComponent } from "../standalone/s25.button.component";
import { S25ErrorsComponent } from "../modules/s25-errors/s25.errors.component";
import { S25SevenPointSolutionsSettingsModule } from "../modules/system-settings/seven-point-solutions-settings/s25.seven.point.solutions.settings.module";
import { UserActivityModule } from "../modules/system-settings/user-activity/user.activity.module";
import { S25ReportFileTypeComponent } from "../standalone/s25.report.file.type.component";
import { S25ObjectSearchModule } from "../modules/s25-object-search/s25.object.search.module";
import { StudentHousingModule } from "../modules/student-housing/student.housing.module";
import { S25LinkParserComponent } from "../standalone/s25.link.parser.component";
import { S25ServicePortalModule } from "../modules/s25-service-portal/s25.service.portal.module";
import { S25AvailWeeklyComponent } from "../modules/s25-avail-weekly/s25.avail.weekly.component";
import { S25AvailLegendComponent } from "../modules/s25-avail-weekly/s25.avail.legend.component";
import { S25InlineDatepickerComponent } from "../standalone/s25.inline.datepicker.component";
import { S25MultiAvailWeeklyComponent } from "../modules/s25-avail-weekly/s25.multi.avail.weekly.component";
import { S25EmbeddedMultiAvailWeeklyComponent } from "../modules/s25-avail-weekly/s25.embedded.multi.avail.weekly.component";
import { SisImportExportSettingsComponent } from "../modules/system-settings/sis-import-export/sis.import.export.component";
import { EventFormModule } from "../modules/event-form/event.form.module";

declare global {
    interface Window {
        angBridge: any;
        angular: any;
        unitTesting: boolean;
    }
}

@NgModule({
    declarations: [AppComponent],
    schemas: [],
    imports: [
        BrowserModule,
        S25HelpModule,
        S25ToggleButtonModule,
        S25EditableBooleanModule,
        S25MultiQueryModule,
        S25AddressModule,
        ModalModule,
        S25NumWeeksChooserModule,
        S25ItemModule,
        PopoverModule,
        S25ImageModule,
        EmailDefaultsModule,
        SystemDefaultsModule,
        UserDefaultsModule,
        EventSettingsModule,
        LocationSettingsModule,
        ResourceSettingsModule,
        OrganizationSettingsModule,
        TaskSettingsModule,
        S25RichTextEditorModule,
        S25BreakpointModule,
        S25DatetimePrefsModule,
        S25TimepickerModule,
        S25DatepickerModule,
        S25DropdownPaginatedModule,
        S25LoadingInlineModule,
        S25DarkmodeModule,
        S25SimpleCollapseModule,
        S25LynxModule,
        S25PricingModule,
        S25NotificationPolicyModule,
        S25LynxModule,
        S25ImageUploadModule,
        S25EditableModule,
        S25EditablePropertyModule,
        BpeModule,
        EventStateDefaultModule,
        S25MultiselectModule,
        S25BulkEditModule,
        S25SecurityLinkModule,
        S25PipesModule,
        S25DeleteModule,
        PublisherSettingsModule,
        S25MoreActionsModule,
        S25ContactInfoModule,
        S25EventTypeHierarchyModule,
        EventFormSettingsModule,
        S25ImageModule,
        S25ImageSelectorModule,
        S25ImageFormModule,
        S25ChangePasswordModule,
        S25MasqueradeModule,
        S25ChangePasswordModule,
        S25ColumnChooserModule,
        S25DayChooserModule,
        S25CancelRequestModule,
        S25PreferenceToggleModule,
        S25RegisterButtonModule,
        S25LocationAlwaysShareModule,
        S25OptModule,
        S25EventStatesModule,
        S25ConflictsModule,
        S25OfficeHoursSliderModule,
        S25TaskModule,
        S25ConstraintsModule,
        S25ObjectModule,
        S25DndSortableModule,
        S25TableModule,
        S25RuleTreeModule,
        S25EventFormConfigsModule,
        S25EmailTemplateModule,
        S25InfoMessageModule,
        S25MasterDefinitionsModule,
        S25EmailScenarioModule,
        S25ChartsModule,
        S25RadioModule,
        S25CustomAttributeModule,
        S25TriggerRerenderModule,
        S25WorkflowModule,
        S25DocumentManagementModule,
        S25TaskViewModule,
        S25EmbeddingModule,
        S25ModalModule,
        S25IconModule,
        S25QLModule,
        S25CaptureEventModule,
        S25PublisherModule,
        S25PillModule,
        S25BoardModule,
        S25ProgressBarModule,
        S25OptimizerEffectiveDatingModule,
        S25ShiftSelectableModule,
        S25BlackoutManagementModule,
        S25AvailabilityModule,
        S25BuildingModule,
        S25StandardScheduleModule,
        S25EventDetailsModule,
        ContextMenuModule,
        S25DurationModule,
        S25EventOccurrencesModule,
        S25StripeSettingsModule,
        S25SevenPointSolutionsSettingsModule,
        S25ObjectAuditListModule,
        S25ScheduledEmailsModule,
        S25MeetingPatternGridModule,
        S25ScrollMinimapComponent,
        S25VirtualGridModule,
        S25ButtonComponent,
        S25ErrorsComponent,
        UserActivityModule,
        S25ButtonComponent,
        S25ReportFileTypeComponent,
        S25ObjectSearchModule,
        StudentHousingModule,
        S25LinkParserComponent,
        S25ServicePortalModule,
        S25AvailWeeklyComponent,
        S25AvailLegendComponent,
        S25InlineDatepickerComponent,
        S25MultiAvailWeeklyComponent,
        S25EmbeddedMultiAvailWeeklyComponent,
        SisImportExportSettingsComponent,
        EventFormModule,
    ],
    providers: [
        provideHttpClient(),
        {
            provide: ErrorHandler,
            useValue: window.unitTesting
                ? null
                : Sentry.createErrorHandler({
                      showDialog: false,
                  }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        AppComponent,
    ],
})
export class AppModule {
    constructor(
        private injector: Injector,
        private zone: NgZone,
        private compileService: CompileService,
    ) {
        ServiceLoader.injector = injector;

        window.angBridge.CompileService = this.compileService;
        window.angBridge.zone = this.zone;
    }

    ngDoBootstrap() {}
}
