//@author
import { Component, ElementRef, NgZone, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "./type.map.service";

declare global {
    interface Window {
        angBridge: any;
        angular: any;
    }
}

@TypeManagerDecorator("app-root")
@Component({
    selector: "app-root",
    template: ``,
})
export class AppComponent implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
    ) {}

    waitForAngularJSToLoad(callback: Function) {
        if (window.angBridge && window.angBridge.$timeout) {
            return callback();
        } else {
            setTimeout(() => {
                this.waitForAngularJSToLoad(callback);
            }, 10);
        }
    }

    ngOnInit() {
        if (this.elementRef && this.elementRef.nativeElement) {
            this.zone.run(() => {
                this.waitForAngularJSToLoad(() => {
                    window.angBridge.$timeout(() => {
                        let uiView = window.angular.element(document.createElement("ui-view"));
                        let uiViewCompiled = window.angBridge.$compile(uiView)(window.angBridge.$rootScope);
                        window.angular.element(this.elementRef.nativeElement).append(uiViewCompiled);
                    });
                });
            });
        }
    }
}
