// Bind "this" to a class method instead of using an arrow function
export function Bind(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    if (typeof descriptor.value !== "function") {
        throw new Error(`@Bind can only be used on methods. ${propertyKey} is not a method.`);
    }
    const original = descriptor.value;
    delete descriptor.value;
    delete descriptor.writable;
    // The bind function is only called once per class & method, regardless of how many times the class is instantiated
    // It attaches the bound method to the prototype.
    // descriptor.get() is ran whenever the class is instantiated, and we need to make sure to return the original in that case
    // The inner get() is called whenever the instance method is accessed

    descriptor.get = function () {
        if (this.hasOwnProperty(propertyKey)) return original; // Ensure we are returning the original whenever class is instantiated

        const bound = original.bind(this);
        Object.defineProperty(this, propertyKey, {
            configurable: true,
            get() {
                return bound;
            },
        });

        return bound;
    };
    return descriptor;
}
