import { Component } from "@angular/core";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";

export class Api {
    static getInstancesFromElem(relativeElement: Element, selector: string): any[] {
        let elements;
        if (relativeElement && selector === "") {
            elements = [relativeElement];
        } else {
            elements = jSith.find(relativeElement, selector) || [];
        }
        return elements
            .map((element: any) => {
                if (element && element.angBridge) {
                    return element.angBridge;
                }
            })
            .filter((element: Component) => {
                return element;
            });
    }

    static callApiFn(
        relativeElem: HTMLElement,
        selector: string,
        method: string,
        argsModel?: any,
        customFunc?: Function,
        compFilter?: (comp: any, i?: number) => boolean,
    ) {
        let promises: Promise<any>[] = [];
        let comps = Api.getInstancesFromElem(relativeElem, selector);
        if (compFilter) {
            comps = comps.filter(compFilter);
        }
        jSith.forEachObj(comps, (comp: any) => {
            //for each component, run a zone and quick timeout to wake up Angular
            let defer = jSith.defer();

            //once that is done, start running API fn and resolve when finished
            let innerDefer = jSith.defer();
            promises.push(innerDefer.promise); //only return once all are finished

            //zone / timeout is done
            defer.promise.then(() => {
                //promise for when API call is done
                let p: Promise<any> = jSith.when();

                if (customFunc) {
                    //custom function
                    let ret: any = customFunc(comp); //run custom function
                    if (ret && ret.then) {
                        //if response is promise, use it
                        p = ret;
                    } else {
                        //else wrap response into promise
                        p = jSith.when(ret);
                    }
                } else if (comp && comp[method]) {
                    //component method
                    let ret: any = comp[method](argsModel); //run method
                    if (ret && ret.then) {
                        //if response is promise, use it
                        p = ret;
                    } else {
                        //else wrap response into promise
                        p = jSith.when(ret);
                    }
                }

                //when call complete, resolve inner promise, which is added to promises array
                //so this whole thing won't return until all innerDefers are complete and those don't
                //start until the zone / timeout defer is done
                p.then(
                    () => {
                        innerDefer.resolve();
                    },
                    (error) => {
                        innerDefer.reject(error);
                    },
                );
            });

            //run zone / timeout
            if (comp.zone && comp.zone.run) {
                comp.zone.run(() => {
                    setTimeout(() => {
                        defer.resolve();
                    }, 1);
                });
            } else {
                setTimeout(() => {
                    defer.resolve();
                }, 1);
            }
        });

        return S25Util.all(promises);
    }
}
