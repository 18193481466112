import { S25Util } from "../util/s25-util";

// Make a function (typically method) debounced by a number of millisecond
export function Debounce(ms?: number, immediate: boolean = false) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        if (typeof descriptor.value !== "function") {
            throw new Error(`@Debounce() can only be used on methods. ${propertyKey} is not a method.`);
        }
        const original = descriptor.value;
        delete descriptor.value;
        delete descriptor.writable;
        const memo = new WeakMap();
        descriptor.get = function () {
            if (!memo.has(this)) memo.set(this, S25Util.debounced(original.bind(this), ms, immediate));
            return memo.get(this);
        };
        return descriptor;
    };
}

// Make a function (typically method) throttled by a number of millisecond
// debounceLast=true: the last call is set to be executed once it's been "ms" ms
// debounceLast=false: if it has not yet been "ms" ms, do nothing
export function Throttle(ms?: number, debounceLast = true) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        if (typeof descriptor.value !== "function") {
            throw new Error(`@Throttle() can only be used on methods. ${propertyKey} is not a method.`);
        }
        const original = descriptor.value;
        delete descriptor.value;
        delete descriptor.writable;
        const memo = new WeakMap();
        descriptor.get = function () {
            if (!memo.has(this)) memo.set(this, S25Util.throttled(original.bind(this), ms, debounceLast));
            return memo.get(this);
        };
        return descriptor;
    };
}
