import { S25Util } from "../util/s25-util";
import { jSith } from "../util/jquery-replacement";

/***************************************************************************************************************************************
To access _repository and _datetime repository use window.angBridge.CacheRepository to be sure we access the global cache at all times.
If using just CacheRepository, we run into issues similar to those reported in ANG-3647.
For TS components injected into JS via <s25-angular-wrapper> any/all cached service methods are stored in a seperate cache.
I'm not sure why, it is almost like a new instance of CacheRepository is created for TS components. Which should not be the case.
Additionally this is only a prod with compiled code (prod-all) dev code works great either way.
-travis
*****************************************************************************************************************************************/
class CacheOptions {
    public immutable?: boolean;
    public cachedMethod?: string;
    public expireTimeMs?: number;
    public ignoreArgsInKey?: boolean;
    public hitNotify?: boolean;
    public targetName: string;
}

class InvalidateOptions {
    public startsWith?: string;
    public objectFunc?: (args: any[]) => any[];
    public validate?: (args: any[]) => boolean;
    public patternFunc?: (args: any[]) => string;
    public serviceName?: string;
    public methodName?: string;
}

export class CacheRepository {
    public static _repository: any = Object.assign({}, (window.ProData && window.ProData.initCache) || {});

    public static _datetimeRepository: any = {};

    public static get(key: string) {
        if (typeof key === "undefined") {
            return undefined;
        }
        return window.angBridge.CacheRepository._repository[key];
    }

    public static getLastRunDateTime(key: string) {
        if (typeof key === "undefined") {
            return undefined;
        }
        return window.angBridge.CacheRepository._datetimeRepository[key];
    }

    public static put(key: string, value: any) {
        if (typeof key === "undefined") {
            return;
        }
        window.angBridge.CacheRepository._repository[key] = value;
        window.angBridge.CacheRepository._datetimeRepository[key] = new Date();
    }

    public static invalidate(key: string) {
        if (typeof key === "undefined") {
            return;
        }
        window.angBridge.CacheRepository._repository[key] = undefined;
        window.angBridge.CacheRepository._datetimeRepository[key] = undefined;
    }

    public static invalidateByStartsWith(startsWith: string, stringPattern?: string) {
        let regExPattern = stringPattern && new RegExp(stringPattern, "gi");
        if (typeof startsWith === "undefined") {
            return;
        }
        let startsWithDelimited = startsWith + "."; //key parts are delimited by "."
        jSith.forEach(window.angBridge.CacheRepository._repository, function (key: string) {
            if (
                (key === startsWith || key.indexOf(startsWithDelimited) === 0) &&
                (!regExPattern || key.search(regExPattern) > -1)
            ) {
                CacheRepository.invalidate(key);
            }
        });
    }

    public static invalidateByObject(service: string, method: string, argsArr: any[]) {
        let key = service + "." + method + "." + S25Util.toJson(CacheRepository.valuesAsString(argsArr)); //args must be an array
        CacheRepository.invalidate(key);
    }

    public static invalidateByObjectPattern(serviceMethod: string, regExPattern?: any) {
        //note: serviceMethod includes serviceName, such as: "PreferenceService.getPreferences"
        CacheRepository.invalidateByStartsWith(serviceMethod, regExPattern);
    }

    public static invalidateByMethod(methodName: string, stringPattern?: string) {
        //note: serviceMethod includes serviceName, such as: "PreferenceService.getPreferences"
        CacheRepository.invalidateByStartsWith(methodName, stringPattern);
    }

    public static invalidateByService(serviceName: string, stringPattern?: string) {
        CacheRepository.invalidateByStartsWith(serviceName, stringPattern);
    }

    public static invalidateAll() {
        window.angBridge.CacheRepository._repository = {};
        window.angBridge.CacheRepository._datetimeRepository = {};
    }

    public static valuesAsString(json: any, cache?: any[]) {
        cache = cache || [];
        if (json) {
            for (let key in json) {
                if (json.hasOwnProperty(key)) {
                    if (S25Util.isObject(json[key]) && cache.indexOf(json[key]) > -1) {
                        continue;
                    } //circular reference
                    cache.push(json[key]);
                    if (S25Util.array.isArray(json[key])) {
                        for (let i = 0; i < json[key].length; i++) {
                            CacheRepository.valuesAsString(json[key][i], cache);
                        }
                    } else if (S25Util.isObject(json[key])) {
                        CacheRepository.valuesAsString(json[key], cache);
                    } else if (!S25Util.isString(json[key]) && S25Util.isNumeric(json[key])) {
                        json[key] = "" + json[key];
                    }
                }
            }
        }
        return json;
    }

    public static composeKey(service: string, method: string, args?: any[], isCacheKeyIgnoreArgs?: boolean) {
        let key = service + "." + method;
        if (!isCacheKeyIgnoreArgs && args && args.length > 0) {
            //note: cannot cache if any argument itself is a function (i.e. callback)
            for (let i = 0; i < args.length; ++i) {
                if (typeof args[i] === "function") {
                    return undefined;
                }
            }

            //convert args to an array so we can invalidate by key easily (ie, key is just an array)
            //then convert any numeric array entries (arguments) to strings so that we can hit and invalidate the cache
            //without regard to argument type. Eg, serviceCall(1) === serviceCall("1") for a cache hit and invalidate(serviceCall("1"))
            //invalidates serviceCall("1") *and* serviceCall(1)
            key += "." + S25Util.toJson(CacheRepository.valuesAsString([].slice.call(args)));
        }
        return key;
    }

    public static handlePromise(promise: any, isDataImmutable?: boolean, hitNotify?: boolean, hitOrMiss?: string) {
        if (promise && promise.forcePromise) {
            return promise.forcePromise();
        }

        if (!isDataImmutable) {
            if (hitNotify) {
                return promise.then(function (data: any) {
                    data.cacheHit = hitOrMiss === "hit";
                    return data;
                });
            } else {
                return promise;
            }
        }

        if (typeof promise.then !== "function") {
            return promise;
        }

        //copy to prevent writes to cache
        return promise.then(function (data: any) {
            if (hitNotify) {
                return Object.assign(S25Util.deepCopy(data), { cacheHit: hitOrMiss === "hit" });
            }
            return S25Util.deepCopy(data);
        });
    }

    public static aspect(
        func: any,
        service: string,
        method: string,
        cachedMethod?: string,
        expireTimeMs?: number,
        isDataImmutable?: boolean,
        isCacheKeyIgnoreArgs?: boolean,
        hitNotify?: boolean,
    ) {
        // forcedMethod - always run original method, get results, and cache results under specified separate [cachedMethod]
        if (cachedMethod) {
            return function (...args: any[]) {
                // note: include our arguments into the key -i.e. assume (cachedMethod) has the same arguments
                let key = CacheRepository.composeKey(service, cachedMethod, args, isCacheKeyIgnoreArgs);

                // note - invalidate cache, so srcMethod running might call cachedMethod and it will have cache miss and will refresh data
                CacheRepository.invalidate(key);

                // run srcMethod
                let promise = func.apply(this, args);

                // note: cache our promise under cacheMethod key in case our method did not call cachedMethod but called DAO directly (legacy)
                // (even though cacheMethod might already been called and might already have cached the promise)
                CacheRepository.put(key, promise);
                return CacheRepository.handlePromise(promise, isDataImmutable, hitNotify, "miss");
            };
        } else {
            // no cachedMethod - normal cache on [service, method]
            // We join all calls to the service to a single call by caching singleton instance of its returned promise.
            // The callers will run .then() method on the cached promise which is the same singleton for all callers.
            // Thus callers will wait till the singleton is resolved.
            return function (...args: any[]) {
                // include the arguments of the method (if any); note - arguments are intercepted at runtime
                let key = CacheRepository.composeKey(service, method, args, isCacheKeyIgnoreArgs);

                let cachedPromise = CacheRepository.get(key);

                // hit
                if (cachedPromise) {
                    // no expireTimeMs specified
                    if (!expireTimeMs) {
                        return CacheRepository.handlePromise(cachedPromise, isDataImmutable, hitNotify, "hit");
                    }

                    // check lastRunTime
                    let lastRunTime = CacheRepository.getLastRunDateTime(key);

                    // if we have expire time and last run time, and are within expire time
                    if (expireTimeMs && lastRunTime && new Date().getTime() - lastRunTime.getTime() < expireTimeMs) {
                        return CacheRepository.handlePromise(cachedPromise, isDataImmutable, hitNotify, "hit");
                    }

                    // expireTimeMs specified, but lastRunTime is older - cannot use cachedPromise, it is cash miss
                    // do nothing - miss hanlded below
                }

                // miss
                // console.log('miss', key);
                let promise = func.apply(this, args);
                // cache the promise returned by original service
                CacheRepository.put(key, promise);

                return CacheRepository.handlePromise(promise, isDataImmutable, hitNotify, "miss");
            };
        }
    }

    public static getCachedMethod(func: any, service: string, method: string, options: CacheOptions) {
        return CacheRepository.aspect(
            func,
            service,
            method,
            options.cachedMethod,
            options.expireTimeMs,
            options.immutable,
            options.ignoreArgsInKey,
            options.hitNotify,
        );
    }

    public static invalidateAspect(func: any, options: InvalidateOptions) {
        return function (...args: any[]) {
            //if validation provided, skip cache invalidation if validate function returns falsy
            if (options.validate) {
                if (!options.validate(args)) {
                    return func.apply(this, args); //call original delegate
                }
            }

            let stringPattern = null;
            if (options.patternFunc) {
                stringPattern = options.patternFunc(args); //extract pattern from args
            }

            if (options.objectFunc) {
                let obj: any = options.objectFunc(args); //extract object from args
                CacheRepository.invalidateByObject(options.serviceName, options.methodName, obj);
            } else if (options.startsWith) {
                CacheRepository.invalidateByStartsWith(options.startsWith, stringPattern);
            } else if (options.serviceName) {
                let serviceName = options.serviceName;
                if (options.methodName) {
                    //if method name give, append it to service name
                    serviceName += "." + options.methodName;
                }
                CacheRepository.invalidateByService(serviceName, stringPattern);
            } else if (options.methodName) {
                CacheRepository.invalidateByMethod(options.methodName, stringPattern);
            }
            return func.apply(this, args); //call original delegate
        };
    }
}

export function Cache(options?: CacheOptions): MethodDecorator {
    options = options || new CacheOptions();
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        let service = options.targetName || target.name;
        let method = propertyKey;

        if ("value" in descriptor) {
            const func = descriptor.value;
            descriptor.value = CacheRepository.getCachedMethod(func, service, method, options);
        } else if ("get" in descriptor) {
            const func = descriptor.get;
            descriptor.get = CacheRepository.getCachedMethod(func, service, method, options);
        }

        return descriptor;
    };
}

export function Invalidate(options?: InvalidateOptions): MethodDecorator {
    options = options || new InvalidateOptions();
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        if ("value" in descriptor) {
            const func = descriptor.value;
            descriptor.value = CacheRepository.invalidateAspect(func, options);
        } else if ("get" in descriptor) {
            const func = descriptor.get;
            descriptor.get = CacheRepository.invalidateAspect(func, options);
        }
        return descriptor;
    };
}
